import { Avatar, Button, Empty, Image, Pagination, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { message, Popconfirm } from "antd";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { GetuserDetails } from "Redux/Features/DetailSlice";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { BlockUser } from "Redux/Features/DetailSlice";
import Cookies from "js-cookie";

const UsersView = () => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { userDetails, loading } = useSelector((state) => state.Details);
  const location = useLocation();
  const [page, setpage] = useState(1);
  const confirm = () => {
    dispatch(BlockUser({ userDetails, id })).then((response) => {
      if (response?.type === "data/blockUser/fulfilled") {
        response?.payload.user.active
          ? message.success("User unblocked Successfully")
          : message.success("User blocked Successfully");
        dispatch(GetuserDetails({ id }));
      } else {
      }
    });
  };

  const cancel = () => {
    // (e);
    return null;
  };

  useEffect(() => {
    !Cookies.get("hedge_token") && history.push("/auth/Login");
    id && dispatch(GetuserDetails({ id }));
  }, [id]);

  useEffect(() => {
    const queries = new URLSearchParams(location.search);
    const _page = queries.get("page");
    if (_page) {
      history.replace(`?page=${page}`);
      setpage(+_page);
    }
  }, []);

  useEffect(() => {
    history.replace(`?page=${page}`);
  }, [page, id]);

  return (
    <div>
      <Container>
        <div>
          <div className=" text-right mb-3">
            <button
              type="button"
              onClick={() => history.goBack()}
              class="btn btn-outline-secondary"
            >
              {" "}
              <i class="nc-icon nc-stre-left mr-1"></i> Back{" "}
            </button>
          </div>

          <div className="border p-4">
            {!loading && (
              <Card className="border-0">
                <Card.Body>
                  <Row className="align-items-center">
                    <Col xl="6">
                      <div className="d-flex align-items-center">
                        <div className="thumbnail-round-lg mr-3">
                          {userDetails.avatar ? (
                            <img
                              alt="..."
                              className="w-100"
                              src={userDetails.avatar}
                            ></img>
                          ) : (
                            <Avatar
                              style={{
                                backgroundColor: "teal",
                                verticalAlign: "middle",
                                left: "20%",
                                top: "10%",
                              }}
                              size={90}
                            >
                              {userDetails?.first_name?.slice(0, 1)}{" "}
                              {userDetails?.last_name?.slice(0, 1)}
                            </Avatar>
                          )}
                        </div>
                        <div>
                          <h2 className="m-0">{userDetails?.full_name}</h2>
                          <p className="text-gary m-0">{userDetails?.email}</p>
                          <h6 className="mb-2 mt-1">
                            <small className="mr-1 text-gary font-weight-bold text-secondary">
                              AGE :
                            </small>
                            {moment().diff(userDetails?.dob, "years")}
                          </h6>
                          <h6 className="mb-2 mt-1">
                            <small className="mr-1 text-gary font-weight-bold text-secondary">
                              Joining Date :
                            </small>
                            {moment(userDetails?.created_at).format(
                              "DD MMMM YYYY"
                            )}
                          </h6>
                          <h6 className="mb-2 mt-1">
                            <small className="mr-1 text-gary font-weight-bold text-secondary">
                              Dob :
                            </small>
                            {moment(userDetails?.dob).format("DD MMMM YYYY")}
                          </h6>
                        </div>
                      </div>
                    </Col>
                    <Col xl="4" className="py-3">
                      <p className="text-gary m-0">
                        <small>REGISTRATION TYPE </small>
                      </p>
                      <h6 className="m-0 mb-2">
                        {userDetails?.provider === "Local"
                          ? "Email"
                          : `${userDetails?.provider} `}{" "}
                      </h6>
                      <p className="text-gary m-0">
                        <small>POINT</small>
                      </p>
                      <h6 className="m-0 text-primary">
                        <b>
                          {userDetails?.point === null ? 0 : userDetails?.point}
                        </b>
                      </h6>
                    </Col>

                    <Popconfirm
                      title={
                        userDetails?.active
                          ? "Are you sure to block this user ?"
                          : "Are you sure to unblock this user ?"
                      }
                      onConfirm={confirm}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Col xl="2" className="py-2">
                        <button
                          type="button"
                          class={
                            userDetails?.active
                              ? "btn btn-danger w-100 m-0"
                              : "btn btn-success w-100 m-0"
                          }
                        >
                          {userDetails?.active ? "Block" : "Unblock"}
                        </button>
                      </Col>
                    </Popconfirm>
                  </Row>
                </Card.Body>
              </Card>
            )}

            {loading && (
              <Card className="border-0">
                <Card.Body>
                  <Row className="align-items-center">
                    <Col xl="6">
                      <div className="d-flex align-items-center">
                        <div className="thumbnail-round-lg mr-3">
                          <Avatar
                            style={{
                              verticalAlign: "middle",
                              left: "20%",
                              top: "10%",
                            }}
                            size={90}
                          >
                            <Skeleton.Image active size={"small"} />
                          </Avatar>

                          {/* {userDetails.avatar ?
                                                    <img
                                                        alt="..." className="w-100"
                                                        src={userDetails.avatar}
                                                    ></img> :
                                                    <Avatar style={{ backgroundColor: 'teal', verticalAlign: 'middle', left: '20%', top: "10%" }} size={90} >
                                                        {userDetails?.first_name?.slice(0, 1)} {userDetails?.last_name?.slice(0, 1)}
                                                    </Avatar>

                                                } */}
                        </div>
                        <div>
                          <h2 className="m-0">
                            <Skeleton.Input active size={"small"} />
                          </h2>
                          <p className="text-gary m-0">
                            <Skeleton.Input active size={"small"} />
                          </p>
                          <br />
                          {/* <h6 className="mb-2 mt-1"><small className="mr-1 text-gary"></small><Skeleton.Input active size={'small'} /></h6> */}
                          <h6 className="mb-2 mt-1">
                            <small className="mr-1 text-gary"></small>
                            <Skeleton.Input active size={"small"} />
                          </h6>
                        </div>
                      </div>
                    </Col>
                    <Col xl="4" className="py-3">
                      <h6 className="m-0 mb-2">
                        <Skeleton.Input active size={"small"} />{" "}
                      </h6>

                      <h6 className="m-0 text-primary">
                        <b>
                          <Skeleton.Input active size={"small"} />
                        </b>
                      </h6>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}

            {loading && (
              <Card className="border-0 m-0">
                <Card.Body className="p-0">
                  <Table
                    responsive
                    className="table-hover table-striped w-full mt-2"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Slip Id</th>
                        <th>Earned on </th>
                        <th>Transaction Id</th>
                        <th>Ticket Cost</th>
                        {/* <th>Joining Date</th> */}
                        <th>Receipt</th>
                      </tr>
                      {userDetails?.reward?.map((item, index) => (
                        <tr key={item.id}>
                          <td>
                            <Skeleton.Input active size={"small"} />
                          </td>
                          <td
                            className="text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              history.push(`/admin/reward-view/${item.id}`)
                            }
                          >
                            <td>
                              <Skeleton.Input active size={"small"} />
                            </td>
                          </td>
                          <td>
                            <Skeleton.Input active size={"small"} />
                          </td>
                          {/* <td><Skeleton.Input active size={'small'} /></td> */}
                          <td>
                            <Skeleton.Input active size={"small"} />
                          </td>
                          {/* <td>4 July 2022</td> */}
                          <td className="receipt-img">
                            <Skeleton.Button
                              active
                              size={"small"}
                              shape={"default"}
                              block={Button}
                            />
                          </td>
                        </tr>
                      ))}
                    </thead>
                  </Table>
                </Card.Body>
              </Card>
            )}

            {!loading && (
              <Card className="border-0 m-0">
                <Card.Body className="p-0">
                  <Table
                    responsive
                    className="table-hover table-striped w-full mt-2"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Slip Id</th>
                        <th>Earned on </th>
                        <th>Transaction Id</th>
                        <th>Total Point</th>
                        <th>Ticket Cost</th>
                        {/* <th>Joining Date</th> */}
                        <th>Receipt</th>
                      </tr>
                      {/* {[...Array(30)].slice(page * 10 - 10, page * 10).map((item, index) => (
                                            <h3>{index}</h3>
                                        ))}
                                        <Pagination
                                            defaultCurrent={1}
                                            total={30}
                                            onChange={(page) => {
                                                setpage(page)
                                                
                                            }}
                                        /> */}
                      {userDetails?.reward
                        ?.slice(page * 10 - 10, page * 10)
                        .map((item, index) => (
                          <tr key={item.id}>
                            <td>{index + 1 + (page - 1) * 10}</td>
                            <td
                              className="text-primary"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                history.push(`/admin/reward-view/${item.id}`)
                              }
                            >
                              {item.slip_id}
                            </td>
                            <td>
                              {moment(item.created_at).format(
                                "DD MMMM YYYY, h:mm a"
                              )}
                            </td>
                            <td>{item.transaction_id}</td>
                            <td>{item.point}</td>
                            <td>
                              ${" "}
                              {item.total_amount === null
                                ? 0
                                : item.total_amount}
                            </td>
                            {/* <td>4 July 2022</td> */}
                            <td className="receipt-img">
                              <Button
                                disabled={!item.image}
                                type="primary"
                                value="small"
                                onClick={() => setVisible(item?.image)}
                              >
                                View Image
                              </Button>

                              <Image
                                width={200}
                                style={{
                                  display: "none",
                                }}
                                src={item.image}
                                preview={{
                                  visible,
                                  //   scaleStep,
                                  src: `${visible}`,
                                  onVisibleChange: (value) => {
                                    setVisible(value);
                                  },
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </thead>
                  </Table>
                  {!userDetails?.reward?.length && <Empty />}
                </Card.Body>
              </Card>
            )}
            <div className="mt-4 d-flex justify-content-end mr-4">
              {userDetails?.reward?.length > 1 ? (
                <Pagination
                  defaultCurrent={1}
                  current={page}
                  total={userDetails?.reward?.length}
                  pageSize={10}
                  onChange={(page) => {
                    setpage(page);
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default UsersView;

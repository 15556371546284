import { configureStore } from '@reduxjs/toolkit'
import CounterReducer from '../Redux/Features/CounterSlice'
import AuthReducer from '../Redux/Features/AuthSlice'
import DetailReducer from '../Redux/Features/DetailSlice'
export const store = configureStore({
  reducer: {
    Counter:CounterReducer,
    Auth:AuthReducer,
    Details:DetailReducer
  },
})

import { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, message, Modal, Radio, Space, Spin, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { sportsBookDetail } from 'Redux/Features/DetailSlice';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImagesToS3 } from 'AWS/awsS3';
import { AddNewSportsBook } from 'Redux/Features/DetailSlice';

const SportsBookModal = ({ setupdateState, open, setOpen }) => {
    const { TextArea } = Input
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [imageurl, setimageurl] = useState();
    const dispatch = useDispatch()
    const { loading } = useSelector(state => state.Counter)
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };
    const RealImage = Form.useWatch('image', form);
    useEffect(() => {
        console.log(RealImage, 'foprm');
        if (!RealImage?.match(/\.(jpeg|png|jpg)$/i)) {
            form.setFieldValue('image', '')
        }
        //   dispatch(sportsBookDetail(1))
    }, [RealImage])

    return (
        <>

            <Modal
                centered
                open={open}
                title={<div style={{ textAlign: 'center' }}>Add New Sportsbook </div>}

                okText="Submit"
                cancelText="Cancel"
                onCancel={() => setOpen(false)}
                width={'35%'}
                onOk={() => {
                    form
                        .validateFields()
                        .then(async (values) => {
                            form.resetFields();
                            // dispatch(AddNewSportsBook(values,imageurl))
                            console.log(imageurl, 'imageurl');

                            setConfirmLoading(true)
                            await uploadImagesToS3(imageurl?.originFileObj).then((res) => {
                                console.log(res.location, 'response after uploading images');
                                if (res.location) {
                                    dispatch(AddNewSportsBook({ values, image: res.location })).then((response) => {
                                        console.log(response, 'after adding book');
                                        if (response.type === "data/addnewsportsbook/fulfilled") {
                                            setupdateState(true)
                                            setupdateState(false)
                                            setOpen(false)
                                            setConfirmLoading(false)
                                            message.success('SportsBook Added Successfully')
                                        }
                                        setConfirmLoading(false)
                                    })
                                }
                            })
                            setimageurl()
                            // console.log(values, 'values');
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Spin spinning={confirmLoading}>
                    <Form
                        form={form}
                        layout="vertical"
                        name="form_in_modal"
                        initialValues={{
                            modifier: 'public',
                        }}
                    >
                        <Form.Item
                            name="State"
                            className='mt-2'
                            rules= {[
                                {
                                  required: true,
                                  message: 'State is required!',
                                },
                                {
                                  whitespace: true,
                                  message: 'State is required!',
                                },
                                {
                                  min: 3,
                                  message: 'Atleast 3 characters required',
                                },
                                {
                                  required: false,
                                  validator: (rule, value = "") => {
                                    if (value.trim().length != 0) {
                                      if (
                                        /^(?![0-9]+$).+$/i.test(
                                          value
                                        )
                                      ) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject("State cannot be only numbers");
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            
                            
                            
                        >
                            <Input size='large' placeholder='State' />
                        </Form.Item>
                        <Form.Item


                            className='my-4'
                            name="Casino"

                            rules={[
                                {
                                    required: true,
                                    
                                    message: 'Casino is Required !'
                                },
                                {
                                    whitespace: true,
                                    
                                    message: 'Casino is Required !'
                                },
                                {
                                    min: 3,
                                    message: 'Atleast 3 characters required'
                                }
                            ]}
                        >
                            <Input size='large' placeholder='Casino' />
                        </Form.Item>
                        <Form.Item
                            className='mt-4'
                            name="address"

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Address is Required !'
                                },
                                {
                                    min: 5,
                                    message: 'Atleast 5 characters  required'
                                }
                            ]}
                        >
                            {/* <TextArea rows={4} placeholder='Address' /> */}
                            <Input size='large' autoSize={{ minRows: 3, maxRows: 6 }} type="textarea" placeholder='Address' />
                        </Form.Item>

                        <Form.Item
                            className='mt-4'
                            name="latitude"

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Latitude is Required !'
                                },


                            ]}
                        >
                            <Input size='large' type="number" placeholder='Latitude' />
                        </Form.Item>
                        <Form.Item
                            className='mt-4'
                            name="logtitude"

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Longitude is Required !',

                                },


                            ]}
                        >
                            <Input size='large' type="number" placeholder='Longitude' />
                        </Form.Item>
                        <Form.Item
                            className='mt-4'
                            name="sportsbook"

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Sportsbook is Required !'
                                },
                                {
                                    min: 3,
                                    message: 'Atleast 3 characters  required'
                                }
                            ]}
                        >
                            <Input size='large' placeholder='SportsBook' />
                        </Form.Item>
                        <Form.Item
                            name="image"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Image is required !'
                                },

                            ]}
                        >
                            <Space direction="vertical" style={{ width: '100%' }} size="large">
                                <Upload
                                    action={null}
                                    listType="picture"
                                    maxCount={1}
                                    onRemove={
                                        () => {
                                            form.setFieldValue('image', '')
                                        }
                                    }
                                    customRequest={
                                        async (options) => {
                                            const { onSuccess, onError, file } = options;
                                            await onSuccess("Ok");
                                        }
                                    }
                                    beforeUpload={(file) => {
                                        const isFileSizeValid = file.size <= 1 * 1024 * 1024;
                                        if (isFileSizeValid) {
                                            const isPNG = file.type === 'image/png' || file.type === 'image/jpeg'|| file.type==='image/jpg ';
                                        if (!isPNG) {
                                            message.error(`${file.name} is not a png/jpeg file`);
                                            form.setFieldValue('image', '')
                                            return Upload.LIST_IGNORE
                                        }else{
                                            return isPNG 
                                        }
                                            
                                        }else{
                                            message.error('Please upload image file within 2MB!');
                                            return Upload.LIST_IGNORE
                                        }
                                        
                                        
                                        // return isPNG || Upload.LIST_IGNORE;
                                    }}
                                    onChange={(info, fileList) => {
                                        console.log(info, 'info');
                                        const isPNG = info.file.type === 'image/png' || info.file.type === 'image/jpeg'|| file.type==='image/jpg ';
                                        console.log(isPNG,'is png');
                                        if (isPNG) {
                                            console.log(isPNG, 'isPNG');
                                            setimageurl(info?.file)
                                        } else {
                                            console.log(isPNG);
                                            form.setFieldValue('image', '')
                                            setimageurl()
                                        }


                                        info.file.status === 'removed' && setimageurl()
                                    }}
                                >
                                    {imageurl ? <img
                                        src={imageurl}
                                        alt=""
                                        style={{
                                            width: '100%',
                                        }}
                                    /> : <Button icon={<UploadOutlined />}>Upload Image</Button>}
                                </Upload>


                            </Space>
                        </Form.Item>



                    </Form>

                </Spin>
            </Modal>
        </>
    );
};
export default SportsBookModal;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// import axios from 'axios'
import Cookies from 'js-cookie'
import axios from '../../utils/Axios'
const initialState = {
  users: [],
  Rewards: [],
  Dashboard: [],
  RegistredUser: [],
  SportsBook: [],
  loading: false
}



export const Getusers = createAsyncThunk(
  'data/getUsers',
  async (obj, { rejectWithValue }) => {
    


    if (obj.filter) {

      try {
    
        const res = await axios.get(`/user?offset=${obj.offset * 10}&limit=10&where={"age_verified":true,"role_id":"2",${obj.filter && obj.filter}}&${obj?.params && `sort=${obj.params}`}&${obj?.search && `search=${obj.search}`}`,
          { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } })
        return res.data.data
  
      } catch (error) {
        return rejectWithValue(error)
  
      }
      
    } else {

      try {
    
        const res = await axios.get(`/user?offset=${obj.offset * 10}&limit=10&where={"age_verified":true,"role_id":"2"}&${obj?.params && `sort=${obj.params}`}&${obj?.search && `search=${obj.search}`}`,
          { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } })
        return res.data.data
  
      } catch (error) {
        return rejectWithValue(error)
  
      }
      
    }
    

  }
)
export const GetRewards = createAsyncThunk(
  'data/getRewards',
  async (obj, { rejectWithValue }) => {

    try {
      const res = await axios.get(`/rewards?offset=${obj.offset * 10}&limit=10&populate=%5B%22user%22%5D&${obj?.params && `sort=${obj.params}`} `,
      
        { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } })
      return res.data.data

    } catch (error) {
      return rejectWithValue(error)

    }

  }
)
export const GetRegistredUsers = createAsyncThunk(
  'data/getRegistredUsers',
  async (obj, { rejectWithValue }) => {

    try {
      const res = await axios.get(`/website-registration?offset=${obj.offset * 10}&limit=10&${obj?.params && `sort=${obj.params}`} `,
        { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } })
      return res.data.data

    } catch (error) {
      return rejectWithValue(error)

    }

  }
)
export const getDashboard = createAsyncThunk(
  'data/getdashboard',
  async (obj, { rejectWithValue }) => {

    const { dateFrom, dateTo } = obj
    try {
      const res = await axios.post(`/user/chart`,
        {
          "start_date": JSON.stringify(dateFrom),
          "end_date": JSON.stringify(dateTo)
        },
        { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } })
      return res.data.data

    } catch (error) {
      return rejectWithValue(error)

    }

  }
)
export const getSportsBook = createAsyncThunk(
  'data/getsportsbook',
  async (obj, { rejectWithValue }) => {
console.log(obj,'params');
    try {
      const res = await axios.get(`/sportsbook?offset=${obj.offset * 10}&limit=${obj.limit}&sort=${obj.sort}&search=${obj.search}`,
        { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } })
      return res.data.data

    } catch (error) {
      
      return rejectWithValue(error)

    }

  }
)

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {

  },
  extraReducers: {
    [Getusers.pending]: (state) => {
      state.loading = true
    },
    [Getusers.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.users = payload

    },
    [Getusers.rejected]: (state) => {
      state.loading = false
    },
    [GetRewards.pending]: (state) => {
      state.loading = true
    },
    [GetRewards.fulfilled]: (state, { payload }) => {
      // console.log(payload,'payload');
      state.loading = false
      state.Rewards = payload

    },
    [GetRewards.rejected]: (state) => {
      state.loading = false
    },
    [getDashboard.pending]: (state) => {
      state.loading = true
    },
    [getDashboard.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.Dashboard = payload

    },
    [getDashboard.rejected]: (state) => {
      state.loading = false
    },
    [GetRegistredUsers.pending]: (state) => {
      state.loading = true
    },
    [GetRegistredUsers.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.RegistredUser = payload

    },
    [GetRegistredUsers.rejected]: (state) => {
      state.loading = false
    },
    [getSportsBook.pending]: (state) => {
      state.loading = true
    },
    [getSportsBook.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.SportsBook = payload

    },
    [getSportsBook.rejected]: (state) => {
      state.loading = false
    },
  }
})

// Action creators are generated for each case reducer function
export const {  } = counterSlice.actions

export default counterSlice.reducer
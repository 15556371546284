/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/demo.css";
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

import Cookies from "js-cookie";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import LoginPage from "views/Pages/LoginPage";
import 'antd/dist/antd.css'
import 'antd/dist/antd.less'
import { Provider } from 'react-redux'
import { store } from "Redux/store";
import PageNotFound from "views/PageNotFound";
import { ConfigProvider } from "antd";
import Main from "layouts/Main";
const token =Cookies.get('hedge_token')


ReactDOM.render(
  <BrowserRouter>

  <Provider store={store}>
  <ConfigProvider
            theme={{
              token: {
                colorPrimary: "green",
                borderRadiusLG: 4,
              },
            }}
          >
    <Switch>
    {/* <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
    <Route path="/auth" render={(props) => <AuthLayout {...props} />} /> */}
       <Main />
       {/* <Route path="/admin/*" element={<Redirect to="/" replace />} /> */}
    </Switch>
    </ConfigProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import axios from '../../utils/Axios'
import axios from '../../utils/Axios'
// import axios from 'axios'
import Cookies from 'js-cookie'
const initialState = {
    loading: false,
    user: []
}

// ASYNC FUNCTION START

export const Login = createAsyncThunk(
    'auth/login',

    async (obj, { rejectWithValue }) => {
        // (obj, 'Lpage');
        try {
            const res = await axios.post(`/auth/local`, {
                username: obj.data.email,
                password: obj.data.password,
                info: {
                    admin: true
                }
            })
            return res
            // if (res.status === 200) {
            //     (res.data); 
            // } else {
            // (res); 
            // }


        } catch (error) {
            return rejectWithValue(error.response.data.message)

        }

    }
)
export const Logout = createAsyncThunk(
    'auth/logout',

    async (obj, { rejectWithValue }) => {
        
        try {
            const res = await axios.post(`/auth/logout`,
                {
                    "session_id": Cookies.get('hedge_session_id')
                },
                { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } },
            )
            return res
            // if (res.status === 200) {
            //     (res.data); 
            // } else {
            // (res); 
            // }


        } catch (error) {
            return rejectWithValue(error.response.data.message)

        }

    }
)
export const ChangePassword = createAsyncThunk(
    'auth/changePassword',

    async (obj, { rejectWithValue }) => {
   
       const {new_password,old_password}=obj
        try {
            const res = await axios.put(`/user/password`,
                {
                   
                        "password": new_password,
                        "old_password": old_password
                     
                },
                { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } },
            )
            return res
            


        } catch (error) {
            return rejectWithValue(error.response.data.message)

        }

    }
)

// ASYNC FUNCTION END

const AuthSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: {
        [Login.pending]: (state) => {
            state.loading = true
            
        },
        [Login.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.user = payload?.data
            
        },
        [Login.rejected]: (state) => {
            state.loading = true
            
        },
        [Logout.pending]: (state) => {
            state.loading = true
            
        },
        [Logout.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.user = payload.data
            
        },
        [Logout.rejected]: (state) => {
            state.loading = true
            
        },
    }
});

export const { } = AuthSlice.actions

export default AuthSlice.reducer
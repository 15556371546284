import { Button, Table, Input, Image, Spin, Popconfirm, message } from 'antd'
import React, { useEffect, useState } from 'react'
import SportsBookModal from './Components/SportsBookModal';
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';
import SportsbookeditModal from './Components/SportsBookeditModal';
import { getSportsBook } from 'Redux/Features/CounterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { SportsBookDetail } from 'Redux/Features/DetailSlice';
import { DeleteSportsbook } from 'Redux/Features/DetailSlice';
import { UploadOutlined } from '@ant-design/icons';
const SportsBook = () => {
    const [open, setOpen] = useState(false);
    const [openedit, setopenedit] = useState(false);
    const [visible, setVisible] = useState(false);
    const [updateState, setupdateState] = useState(false);
    const { SportsBook, loading } = useSelector(state => state.Counter)
    const dispatch = useDispatch()
    const [scaleStep, setScaleStep] = useState(0.5);
    const [sortDir] = useState(['ascend', 'descend', 'ascend']);
    const { Search } = Input
    const [params, setParams] = useState({
        page: 1,
        offset: 0,
        limit: 10,
        sort: `[["id", "desc"]]`,
        filter: "",
        search: '',
        id: true
    });
    const [sortOrder, setsortOrder] = useState({
        state: false,
        casino: false,
        address: false,
        latitude: false,
        longitude: false,
        sportsbook: false
    });

    const [basicData, setBasicData] = useState({
        rows: []
    });

    const columns = [
        // {
        //     title: '#',
        //     key: 'index',
        //     render: (text, record, index, prop) => SportsBook./10-1* 10 + index + 1   ,
        //   },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            onHeaderCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setParams({
                            ...params,
                            sort: `[["state", ${!sortOrder.state ? ` "asc" ` : ` "desc" `}]]`,
                        })
                        // sessionStorage.setItem("Reward_key", `[["total_amount", ${sort.total_cost ? ` "asc" ` : ` "desc" `}]]`)

                        setsortOrder({
                            state: !sortOrder.state
                        })
                    },
                };
            },
            sorter:true,
            sortDirections:sortDir
        },
        {
            title: 'Casino',
            dataIndex: 'casino',
            key: 'casino',
            onHeaderCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setParams({
                            ...params,
                            sort: `[["casino", ${!sortOrder.casino ? ` "asc" ` : ` "desc" `}]]`,
                        })
                        // sessionStorage.setItem("Reward_key", `[["total_amount", ${sort.total_cost ? ` "asc" ` : ` "desc" `}]]`)

                        setsortOrder({
                            casino: !sortOrder.casino
                        })
                    },
                };
            },
            sorter:true,
            sortDirections:sortDir
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            onHeaderCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setParams({
                            ...params,
                            sort: `[["address", ${!sortOrder.address ? ` "asc" ` : ` "desc" `}]]`,
                        })
                        // sessionStorage.setItem("Reward_key", `[["total_amount", ${sort.total_cost ? ` "asc" ` : ` "desc" `}]]`)

                        setsortOrder({
                            address: !sortOrder.address
                        })
                    },
                };
            },
            sorter:true,
            sortDirections:sortDir
        },
        {
            title: 'Latitude',
            dataIndex: 'latitude',
            key: 'latitude',
            onHeaderCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setParams({
                            ...params,
                            sort: `[["latitude", ${!sortOrder.latitude ? ` "asc" ` : ` "desc" `}]]`,
                        })
                        // sessionStorage.setItem("Reward_key", `[["total_amount", ${sort.total_cost ? ` "asc" ` : ` "desc" `}]]`)

                        setsortOrder({
                            latitude: !sortOrder.latitude
                        })
                    },
                };
            },
            sorter:true,
            sortDirections:sortDir
        },
        {
            title: 'Longitude',
            dataIndex: 'longitude',
            key: 'longitude',
            onHeaderCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setParams({
                            ...params,
                            sort: `[["longitude", ${!sortOrder.longitude ? ` "asc" ` : ` "desc" `}]]`,
                        })
                        // sessionStorage.setItem("Reward_key", `[["total_amount", ${sort.total_cost ? ` "asc" ` : ` "desc" `}]]`)

                        setsortOrder({
                            longitude: !sortOrder.longitude
                        })
                    },
                };
            },
            sorter:true,
            sortDirections:sortDir
        },
        {
            title: 'Sportsbook',
            dataIndex: 'sportsbook',
            key: 'sportsbook',
            onHeaderCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setParams({
                            ...params,
                            sort: `[["sportsbook", ${!sortOrder.sportsbook ? ` "asc" ` : ` "desc" `}]]`,
                        })
                        // sessionStorage.setItem("Reward_key", `[["total_amount", ${sort.total_cost ? ` "asc" ` : ` "desc" `}]]`)

                        setsortOrder({
                            sportsbook: !sortOrder.sportsbook
                        })
                    },
                };
            },
            sorter:true,
            sortDirections:sortDir
        },
        {
            title: '',
            key: 'operation',
            // fixed: 'right',
            width: 100,
            render: (prop) => <h5 class="text-center">
                <a 
                onClick={() => {
                    console.log(prop, 'prp');
                    setVisible(prop.image)

                }} className='btn btn-primary btn-sm '>
                    View Image
                </a>
            </h5>,
        },
        {
            title: '',
            key: 'operation',
            // fixed: 'right',
            width: 100,
            render: (prop) => <h5 onClick={() => {
                setopenedit(true)
                dispatch(SportsBookDetail(prop, 'fnl prop'))
            }} class="text-center">
                <a >
                    <h5 className='small'><i class="fa fa-pen text-primary"></i></h5>
                </a>
            </h5>,
        },
        {
            title: '',
            key: 'operation',
            // fixed: 'right',
            width: 100,
            render: (prop) => <Popconfirm
            title="Are you sure to delete this SportsBook?"
            onConfirm={() =>dispatch(DeleteSportsbook(prop?.id)).then((response)=>{
                response.type==="data/deletesportsbook/fulfilled" && message.success('SportsBook Removed Successfully')
                setupdateState(true)
                setupdateState(false)

                // window.location.href="/admin/sportsbook"
            }) }
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
    
            <p className="text-center">
              <a >
                <i
    
                  className="fa fa-trash text-danger"></i>
              </a>
            </p>
          </Popconfirm>,
        },

    ];

    useEffect(() => {
       
        dispatch(getSportsBook(params)).then((response) => {
            
            setBasicData({

                rows: response?.payload?.Sportsbook?.map((i) => (
                    {
                        key: i.id,
                        id: i.id,
                        // user_id: i.user.id,
                        latitude: i.latitude,
                        longitude: i.longitude,
                        sportsbook: i.sportsbook,
                        state: i.state,
                        casino: i.casino,
                        address: i.address,
                        image: i.image

                    }
                ))
            })
        })
        
    }, [params,updateState])



    return (
        <div>


            <Container fluid>
                <Row>
                    {openedit && <SportsbookeditModal setupdateState={setupdateState}   openedit={openedit} setopenedit={setopenedit} />}
                    {open && <SportsBookModal setupdateState={setupdateState}  open={open} setOpen={setOpen} />}
                    <Col md="12">
                        <div className="pg_hdr">
                            <div className="hdng">
                                <h4 className="title mt-0">
                                    SportsBook 
                                </h4>
                            </div>
                            <div className="btng ">
                                <div className="search ">
                                    <Search
                                    onChange={(e) => {
                                        setParams({
                                          ...params,
                                          search: e.target.value,
                                          offset:0,
                                          page:1
                                        })
                                      }}
                                     placeholder="search" />

                                </div>
                                <div className="btnM ml-4">
                                    <Button type='primary' className='me-3' onClick={() => {
                                        setOpen(true)
                                    }}>Add New Sportsbook</Button>
                                </div>



                            </div>

                        </div>

                        <Card className="border-0 mt-2">
                            <Card.Body className="p-0">
                                <Spin spinning={loading}>
                                    <Table
                                        size='middle'
                                        dataSource={basicData.rows}
                                        columns={columns}
                                        pagination={
                                            {
                                                total: SportsBook.count,
                                                // defaultCurrent:params.page,
                                                current:params.page,
                                                onChange: (page) => {
                                                    
                                                    setParams({
                                                        ...params,
                                                        page: page,
                                                        offset: page - 1

                                                    })
                                                }
                                            }
                                        }
                                    />;

                                </Spin>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Image
                    width={100}
                    height={200}
                    style={{ display: 'none' }}
                    src={visible}
                    preview={{
                        icons:<UploadOutlined/>,
                        visible,
                        scaleStep,
                        src: visible,
                        onVisibleChange: (value) => {
                            setVisible(value);
                        },
                    }}
                />
            </Container>

        </div>
    )
}

export default SportsBook

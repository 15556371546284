import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// import axios from 'axios'
import Cookies from 'js-cookie'
import axios from '../../utils/Axios'
const initialState = {
    loading: false,
    userDetails: {},
    rewarddetails: {},
    settingsPagePoiint: {},
    sportsBookDetail: {}

}
export const GetuserDetails = createAsyncThunk(
    'data/getUserDetails',
    async (obj, { rejectWithValue }) => {
        // (obj,'obj');
        try {
            const res = await axios.get(`/user/${obj.id}?populate=%5B%22reward%22%5D`,
                { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } })
            return res.data.data

        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const BlockUser = createAsyncThunk(
    'data/blockUser',
    async (obj, { rejectWithValue }) => {

        const { userDetails, id } = obj
        try {
            const res = await axios.put(`/user/${id}`,
                {

                    "active": !userDetails?.active,
                    "full_name": userDetails?.full_name,
                    "email": userDetails?.email,
                    "age": userDetails?.age,
                    "phone_code": userDetails?.phone_code,
                    "phone": userDetails?.phone,
                    "avatar": userDetails?.avatar,
                    "email_verified": userDetails?.email_verified,
                    "age_verified": userDetails?.age_verified,
                    "send_email": userDetails?.send_email,
                    "send_sms": userDetails?.send_sms,
                    "send_push": userDetails?.send_push,
                    "point": userDetails?.point,
                    // "avatar_file": "string"

                },
                { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } })
            return res.data.data

        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const GetRewardDetails = createAsyncThunk(
    'data/getRewardDetails',
    async (obj, { rejectWithValue }) => {

        try {
            const res = await axios.get(`/rewards/${obj.id}?populate=%5B%22user%22%5D`,
                { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } })
            return res.data.data.rewards

        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const GetSettingsPageData = createAsyncThunk(
    'data/getSettingsPageData',
    async (obj, { rejectWithValue }) => {
        (obj, 'obj');
        try {
            const res = await axios.get(`/point-matser/1`,

                { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } })
            return res.data.data.point

        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const UpdateSettingsPageData = createAsyncThunk(
    'data/settingsPageUpdatew',
    async (obj, { rejectWithValue }) => {

        try {
            const res = await axios.put(`/point-matser/1`,
                {
                    "active": true,
                    "point": obj.point
                },
                { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } })
            return res.data.data.point

        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const AddNewSportsBook = createAsyncThunk(
    'data/addnewsportsbook',
    async (obj, { rejectWithValue }) => {
console.log(obj,'add book');
        try {
            const res = await axios.post(`/sportsbook`,
            {
                "active": true,
                "state": obj.values.State,
                "casino": obj.values.Casino,
                "address": obj.values.address,
                "latitude": obj.values.latitude,
                "longitude": obj.values.logtitude,
                "sportsbook": obj.values.sportsbook,
                "image": obj.image
              },
                { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } })
            return res.data.data.point

        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const UpdateSportsBook = createAsyncThunk(
    'data/updatesportsbook',
    async (obj, { rejectWithValue }) => {
console.log(obj,'update book');
        try {
            const res = await axios.put(`/sportsbook/${obj.id}`,
            {
                "active": true,
                "state": obj.values.State,
                "casino": obj.values.Casino,
                "address": obj.values.address,
                "latitude": obj.values.latitude,
                "longitude": obj.values.logtitude,
                "sportsbook": obj.values.sportsbook,
                "image": obj.image
              },
                { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } })
            return res.data.data.point

        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const SportsBookDetail = createAsyncThunk(
    'data/sportspagedetail',
    async (obj, { rejectWithValue }) => {
       
        try {
            const res = await axios.get(`/sportsbook/${obj.id}`,
                { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } })
            return res.data.data.Sportsbook

        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const DeleteSportsbook = createAsyncThunk(
    'data/deletesportsbook',
    async (obj, { rejectWithValue }) => {
       console.log(obj,'obj in delete');
        try {
            const res = await axios.delete(`/sportsbook/${obj}`,
                { headers: { Authorization: `Bearer ${Cookies.get('hedge_token')}` } })
            return res.data.data.Sportsbook

        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
const DetailSlice = createSlice({
    name: "detail",
    initialState,
    reducers: {},
    extraReducers: {
        [GetuserDetails.pending]: (state) => {
            state.loading = true
        },
        [GetuserDetails.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.userDetails = payload.user

        },
        [GetuserDetails.rejected]: (state) => {
            state.loading = false
        },
        [GetRewardDetails.pending]: (state) => {
            state.loading = true
        },
        [GetRewardDetails.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.rewarddetails = payload

        },
        [GetRewardDetails.rejected]: (state) => {
            state.loading = false
        },
        [GetSettingsPageData.pending]: (state) => {
            state.loading = true
        },
        [GetSettingsPageData.fulfilled]: (state, { payload }) => {
            state.loading = false

            state.settingsPagePoiint = payload

        },
        [GetSettingsPageData.rejected]: (state) => {
            state.loading = false
        },
        [SportsBookDetail.pending]: (state) => {
            state.loading = true
            state.sportsBookDetail = {}
        },
        [SportsBookDetail.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.sportsBookDetail = payload

        },
        [SportsBookDetail.rejected]: (state) => {
            state.loading = false
        },
    }
});

export const { } = DetailSlice.actions

export default DetailSlice.reducer
import React, { useEffect, useState } from "react";
// react component used to create charts
import ChartistGraph from "react-chartist";
// react components used to create a SVG / Vector map
import { VectorMap } from "react-jvectormap";
import moment from "moment";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  OverlayTrigger,
  Table,
  Tooltip,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Alert, DatePicker, Skeleton, Space, Spin } from "antd";
const { RangePicker } = DatePicker;
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard } from "Redux/Features/CounterSlice";
import { Redirect, useHistory } from "react-router";
import Cookies from "js-cookie";
import { CardFooter } from "reactstrap";

const {
  allowedMaxDays,
  allowedDays,
  allowedRange,
  beforeToday,
  afterToday,
  combine,
} = DateRangePicker;
function Dashboard() {
  const [graphData, setgrapgData] = useState({
    labels: [],
    series: [[]],
    series1: [[]],
  });
  const [dateTo, setdateTo] = useState(moment().format("YYYY-MM-DD"));
  const [dateFrom, setdateFrom] = useState(
    moment().subtract(15, "d").format("YYYY-MM-DD")
  );

  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  const [dasLoading, setdasLoading] = useState(false);
  const dispatch = useDispatch();
  const { Dashboard, loading } = useSelector((state) => state.Counter);
  const history = useHistory();
  const token = Cookies.get("hedge_token");
  const truncateDecimals = function (number) {
    return Math[number < 0 ? "ceil" : "floor"](number);
  };
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const future = dates[0] && current.valueOf() > Date.now();
    const futuresec = dates[1] && current.valueOf() > Date.now();
    const tooLate = dates[0] && current.diff(dates[0], "days") > 7;
    // const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
    return !!tooEarly || !!tooLate || !!future || !!futuresec;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };
  useEffect(() => {
    !Cookies.get("hedge_token") && history.push("/auth/Login");
  }, [token]);

  useEffect(() => {
    dispatch(getDashboard({ dateTo, dateFrom })).then((response) => {
      if (response.type === "data/getdashboard/fulfilled") {
        setgrapgData({
          labels: response?.payload?.reward_graph[0],
          series: response?.payload?.reward_graph[1],
          series1: response?.payload?.user_grapg[1],
        });
      }
    });
  }, [dateTo, dateFrom, dispatch]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="4" sm="6">
            <Card className="card-stats">
              <Card.Body
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/admin/Users/:id")}
              >
                <Row className="align-items-center">
                  <Col xs="5">
                    <div className="icon-big text-left icon-warning">
                      <i className="nc-icon nc-circle-09 text-primary"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <Card.Title as="h4">Users </Card.Title>

                      {loading ? (
                        <Card.Title as="h4">
                          <Skeleton.Input
                            width={2}
                            title={true}
                            active
                            size={"small"}
                          />
                        </Card.Title>
                      ) : (
                        <Card.Title as="h4">({Dashboard?.user})</Card.Title>
                      )}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col lg="4" sm="6">
            <Card className="card-stats">
              <Card.Body
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/admin/Rewards/:id")}
              >
                <Row className="align-items-center">
                  <Col xs="5">
                    <div className="icon-big text-left icon-warning">
                      <i className="nc-icon nc-chart-bar-32 text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <Card.Title as="h4">Scanned Receipts</Card.Title>
                      {loading ? (
                        <Skeleton.Input active size={"small"} />
                      ) : (
                        <Card.Title as="h4"> ({Dashboard?.recipts})</Card.Title>
                      )}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col lg="4" sm="6">
            <Card className="card-stats">
              <Card.Body
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/admin/Rewards/:id")}
              >
                <Row className="align-items-center">
                  <Col xs="5">
                    <div className="icon-big text-left icon-warning">
                      <i className="nc-icon nc-money-coins text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <Card.Title as="h4">Rewards</Card.Title>
                      {loading ? (
                        <Skeleton.Input active size={"small"} />
                      ) : (
                        <Card.Title as="h4">
                          {" "}
                          ({truncateDecimals(Dashboard?.rewards * 100) / 100})
                        </Card.Title>
                      )}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title as="h4">Users </Card.Title>
                <p className="card-category">User Registration performance</p>
                <DateRangePicker
                  ranges={[]}
                  editable={false}
                  cleanable={false}
                  placeholder="Select Date"
                  defaultValue={[
                    new Date(new Date().getTime() - 15 * 24 * 60 * 60 * 1000),
                    new Date(),
                  ]}
                  format="yyyy-MM-dd"
                  onChange={(date) => {
                    setdateFrom(
                      date[0] && moment(date[0]).format("YYYY-MM-DD")
                    );
                    setdateTo(date[1] && moment(date[1]).format("YYYY-MM-DD"));
                  }}
                  disabledDate={combine(allowedMaxDays(365), afterToday())}
                />
                {/* <DateRangePicker 
                  editable={false}
                  defaultValue={[new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)), new Date()]}
                  disabledDate={combine(allowedMaxDays(7), beforeToday())} /> */}
              </Card.Header>
              <Card.Body>
                <Spin spinning={loading}>
                  <ChartistGraph
                    type="Line"
                    data={{
                      labels: graphData.labels,
                      series: [graphData?.series, graphData?.series1],
                    }}
                    options={{
                      lineSmooth: true,

                      height: "260px",
                      axisY: {
                        offset: 40,
                        labelInterpolationFnc: function (value) {
                          return "" + value;
                        },
                      },
                      low: 0,
                      high:
                        Math.max(
                          ...graphData.series.concat(graphData.series1)
                        ) > 50
                          ? Math.max(
                              ...graphData.series.concat(graphData.series1)
                            ) + 50
                          : 50,

                      chartPadding: {
                        right: -25,
                      },
                    }}
                    responsiveOptions={[
                      [
                        "screen and (max-width: 640px)",
                        {
                          axisX: {
                            labelInterpolationFnc: function (value) {
                              return value[0];
                            },
                          },
                        },
                      ],
                    ]}
                  />
                </Spin>
              </Card.Body>
              <CardFooter
                style={{
                  paddingTop: "0px",
                }}
              >
                <div className="legend ml-5">
                  <i className="fas fa-circle text-info"></i>
                  Rewards Earned{" "}
                  <i className="fas fa-circle text-danger ml-5"></i>
                  Users Registered
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;

import React, { useEffect, useState } from "react";

// react-bootstrap components
import { Input, Select, Skeleton, Table } from "antd";
import {
  Button,
  Card,
  Col,
  // Table,
  Container,
  Form,
  Row,
} from "react-bootstrap";
// core components
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Getusers } from "Redux/Features/CounterSlice";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import { GetRegistredUsers } from "Redux/Features/CounterSlice";
function ReactTables() {
  const { users, RegistredUser, loading } = useSelector(
    (state) => state.Counter
  );
  const history = useHistory();
  const { id } = useParams();
  console.log(id, "id");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [sort, setSort] = useState({
    name: false,
    email: false,
    joindate: false,
    age: false,
    reg_type: false,
    point: false,
    last_name: false,
  });
  var item_value = sessionStorage.getItem("item_key");

  // ANTDATA

  const { Search } = Input;
  const [basicData, setBasicData] = useState({
    rows: [],
  });
  // const [page, setpage] = useState(id == ':id' ? 0 : id - 1)
  const page = id == ":id" ? 0 : id - 1;
  // console.log(page,'page');
  const columns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index, prop) => page * 10 + index + 1,
    },
    {
      title: " First Name",
      dataIndex: "name",

      onHeaderCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setParams({
              ...params,
              sort: `[["full_name",${sort.name ? ` "desc" ` : ` "asc" `}]]`,
            });
            sessionStorage.setItem(
              "item_key",
              `[["first_name",${sort.name ? ` "desc" ` : ` "asc" `}]]`
            );
            setSort({
              name: !sort.name,
            });
          },
        };
      },

      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: "10%",

      // responsive: ["sm"]
    },
    {
      title: " Last Name",
      dataIndex: "l_name",

      onHeaderCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setParams({
              ...params,
              sort: `[["last_name",${sort.name ? ` "desc" ` : ` "asc" `}]]`,
            });
            sessionStorage.setItem(
              "item_key",
              `[["last_name",${sort.name ? ` "desc" ` : ` "asc" `}]]`
            );
            setSort({
              name: !sort.name,
            });
          },
        };
      },

      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: "10%",

      // responsive: ["sm"]
    },
    {
      title: "E-mail",
      dataIndex: "email",

      onHeaderCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setParams({
              ...params,
              sort: `[["email",${sort.email ? ` "desc" ` : ` "asc" `}]]`,
            });
            sessionStorage.setItem(
              "item_key",
              `[["email",${sort.email ? ` "desc" ` : ` "asc" `}]]`
            );
            setSort({
              email: !sort.email,
            });
          },
        };
      },
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      // responsive: ["sm"]
    },
    {
      title: "Joining Date",
      dataIndex: "joindate",
      onHeaderCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setParams({
              ...params,
              sort: `[["created_at",${
                sort.joindate ? ` "desc" ` : ` "asc" `
              }]]`,
            });
            sessionStorage.setItem(
              "item_key",
              `[["created_at",${sort.joindate ? ` "desc" ` : ` "asc" `}]]`
            );
            setSort({
              joindate: !sort.joindate,
            });
          },
        };
      },
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      // responsive: ["sm"]
    },
  ];

  // ANTDATA
  const provider = "provider";
  const active = "active";
  const Local = "Local";
  const [drpValues, setdrpValues] = useState({
    type: "",
    status: "",
  });
  const [params, setParams] = useState({
    populate: `["location","user","-locations","service","service.service"]`,
    page: 1,
    offset: 0,
    limit: 10,
    sort: `[["created_at", "desc"]]`,
    filter: ``,
    search: "",
    where: { active: true, is_claimed: true },
  });

  const dispatch = useDispatch();
  useEffect(() => {
    sessionStorage.setItem("item_key", `[["created_at", "desc" ]]`);
  }, []);

  // const onFilter =() =>{
  //   console.log(params.filter);
  //   dispatch(Getusers({ offset: 0, params: item_value, search: params.search, filter: params.filter })).then((res) => {

  //     setBasicData({

  //       rows: res?.payload?.users?.map((i) => (
  //         {
  //           key: i.id,
  //           _id: i.id,
  //           name: i.full_name,
  //           email: i.email,
  //           joindate: moment(i.created_at).format("DD MMMM YYYY, h:mm a"),
  //           Age: moment().diff(i.dob, 'years'),
  //           Point: i.point == null ? 0 : i.point,
  //           RegistrationType: i.provider === "Local" ? "E-mail " : `${i.provider} `,
  //           Status: i.active ? 'Active' : 'Blocked',

  //         }
  //       ))
  //     })

  //   })
  // }
  useEffect(() => {
    console.log(params.filter);
    params.filter &&
      dispatch(
        GetRegistredUsers({
          offset: page,
          params: item_value,
          search: params.search,
          filter: params.filter,
        })
      ).then((res) => {
        console.log(res.payload.point, "response after fetching data");

        setBasicData({
          rows: res?.payload?.point?.map((i) => ({
            key: i.id,
            _id: i.id,
            name: i.first_name,
            email: i.email,
            l_name: i.last_name,
            joindate: moment(i.created_at).format("DD MMMM YYYY, h:mm a"),
          })),
        });
      });
  }, [params.filter]);

  useEffect(() => {
    !Cookies.get("hedge_token") && history.push("/auth/Login");
    dispatch(
      GetRegistredUsers({
        offset: page,
        params: item_value,
        search: params.search,
        filter: params.filter,
      })
    ).then((res) => {
      setBasicData({
        rows: res?.payload?.point?.map((i) => ({
          key: i.id,
          _id: i.id,
          name: i.first_name,
          email: i.email,
          l_name: i.last_name,
          joindate: moment(i.created_at).format("DD MMMM YYYY, h:mm a"),
        })),
      });
    });
  }, [params.sort, params.search]);

  const onSubmit = async (data) => {};

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title mt-0">Pre Registrations</h4>

            <Card className="border-0 mt-2">
              <Card.Body className="p-0">
                {/* <div className="p-3">
                  <Row className="d-flex justify-content-end mx-4">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Col lg="4" className="d-flex justify-contenr-center">

                        <div className="mr-4">

                          <Select
                            {...register('status', {

                              // required: 'status is Required',
                              // validate: (value) => !value.trim() == "",
                              // minLength: { value: 6, message: 'Minimum 6 characters required' }
                            })}
                            value={drpValues.status === '' ? null : drpValues.status}
                            style={{ width: 120 }}
                            size="large"
                            name='status,'
                            placeholder='Status'
                            onChange={(value) => {
                              setdrpValues({
                                ...drpValues,
                                status: value
                              })

                              setParams({
                                ...params,
                                filter: [...params.filter, `${JSON.stringify(active)} : ${value}`]
                              },)
                              // setTimeout(() => {
                              //   onFilter()
                              // }, 3000);
                              
                            }}
                            // onChange={handleChange}
                            options={[
                              { value: 'true', label: 'Active' },
                              { value: 'false', label: 'Blocked' },

                            ]}
                          />
                        </div>
                        <div>
                          <Select
                            value={drpValues.type === '' ? null : drpValues.type}
                            style={{ width: 120 }}
                            placeholder='Type'
                            size="large"
                            onChange={(value) => {
                              setdrpValues({
                                ...drpValues,
                                type: value
                              })
                              setParams({
                                ...params,
                                filter: [...params.filter, `${JSON.stringify(provider)} : ${JSON.stringify(value)}`]
                              })
                              history.push(`/admin/Users/:id`)
                            }}
                            options={[
                              { value: 'Apple', label: 'Apple' },
                              { value: "Local", label: 'Email' },
                              { value: 'Facebook', label: 'Facebook' },
                              { value: 'Google', label: 'Google' },


                            ]}
                          />
                        </div>




                        <Button
                          type='reset'
                          onClick={() => {
                            setParams({

                              search: null
                            })
                            setdrpValues({
                              status: '',
                              type: ''
                            })

                            setParams({
                              filter: ''
                            })
                          }}
                          className="ml-3"> Clear
                        </Button>

                      </Col>
                    </form>
                    <Col lg="4">
                      {/* <Form className="d-flex ml-2">
                        <Form.Control
                          type="search"
                          placeholder="Search"
                          value={params?.search}
                          className="me-2"
                          aria-label="Search"
                          onChange={(e) => {
                            setParams({

                              search: e.target.value
                            })
                          }}
                        />
                        
                      </Form> */}
                {/* <Search
                        className="searchBar"
                        placeholder="Search "
                        value={params.search}
                        onChange={(e) => {
                          setParams({
                            ...params,
                            search: e.target.value
                          })
                        }}
                        style={{
                          width: 350,
                          height: 40,
                          marginTop: 9,
                          color: 'black'
                        }} */}

                {/* /> */}
                {/* <Form.Select aria-label="Default select example">
                <option>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select> */}

                {/* </Col>

                  </Row> */}
                {/* </div> */}

                {/* <div className="container">
                <Input.Search 
                onChange={(input)=>{
                 
                //  alert('hi')
                }}
                />
                </div> */}

                <Row className="mt-4 px-3">
                  <Col sm="7" lg={5}>
                    <Card className="card-stats w-75">
                      <Card.Body>
                        <Row className="align-items-center">
                          <Col xs="3">
                            <div className="icon-big text-left icon-warning">
                              <i className="nc-icon nc-circle-09 text-primary"></i>
                            </div>
                          </Col>
                          <Col xs="9">
                            <div className="numbers">
                              <Card.Title as="h4">
                                Total Pre Registrations{" "}
                              </Card.Title>
                              {loading ? (
                                <Card.Title as="h4">
                                  <Skeleton.Input
                                    width={2}
                                    title={true}
                                    active
                                    size={"small"}
                                  />
                                </Card.Title>
                              ) : (
                                <Card.Title as="h4">
                                  ({RegistredUser.count})
                                </Card.Title>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Table
                  pagination={{
                    showSizeChanger: false,
                    defaultCurrent: id == ":id" ? 1 : parseInt(id),
                    current: id == ":id" ? 1 : parseInt(id),
                    pageSize: 10,
                    total: RegistredUser?.count,
                    onChange: (page) => {
                      history.push(`/admin/new-users/${page}`);
                      // alert('hhhhh')
                      dispatch(
                        GetRegistredUsers({
                          offset: page - 1,
                          params: item_value,
                          search: params.search,
                          filter: params.filter,
                        })
                      ).then((res) => {
                        // (res.payload, 'usr');
                        setBasicData({
                          rows: res?.payload?.point?.map((i) => ({
                            key: i.id,
                            _id: i.id,
                            name: i.first_name,
                            email: i.email,
                            l_name: i.last_name,
                            joindate: moment(i.created_at).format(
                              "DD MMMM YYYY, h:mm a"
                            ),
                          })),
                        });
                      });
                    },
                  }}
                  size="middle"
                  loading={loading}
                  columns={columns}
                  dataSource={basicData.rows}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ReactTables;

import { useEffect, useState } from "react";
// react component used to create charts
// react components used to create a SVG / Vector map
import { useForm } from 'react-hook-form';
import "antd/dist/antd.css"
// react-bootstrap components
import { Alert, message } from "antd";
import Cookies from "js-cookie";
import {
  Button,
  Card, Col, Container, Form, Modal, Row
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "reactstrap";
import { ChangePassword, Logout } from "Redux/Features/AuthSlice";
import { useHistory } from "react-router";
import { GetSettingsPageData } from "Redux/Features/DetailSlice";
import PointCalculation from "./PointCalculation";

function Settings() {
  const [invalidCurrentPswrd, setinvalidCurrentPswrd] = useState(false);
  const [lgShow1, setLgShow1] = useState(false);
  const dispatch = useDispatch()
  const { settingsPagePoiint, loading } = useSelector(state => state.Details)
  const { register, handleSubmit, control, reset, watch, trigger, formState: { errors } } = useForm()
  const history = useHistory()

  const onSubmit = async (data) => {


    setinvalidCurrentPswrd(false)
    const { new_password, old_password, confirm_password } = data
    if (new_password != confirm_password) {

    } else {
      dispatch(ChangePassword({
        new_password, old_password
      })).then((response) => {

        if (response.type === "auth/changePassword/fulfilled") {

          setLgShow1(false)
          message.success(response?.payload?.data?.message)
          dispatch(Logout()).then((res) => {
            if (res.type === "auth/logout/fulfilled") {


              Cookies.remove('hedge_token')
              Cookies.remove('hedge_session_id')
              window.location.href = "/auth/Login"
              // history.push("/auth/Login")
            } else {

            }
          })
        } else {

          setinvalidCurrentPswrd(true)
        }
        // response.type=== "auth/changePassword/fulfilled" ? message.success(response?.payload?.data?.message) :message.success(response?.payload)
        reset()
      })
    }




  }
  useEffect(() => {
    !Cookies.get('hedge_token') && history.push('/auth/Login')
  }, [])


  useEffect(() => {
    dispatch(GetSettingsPageData()).then((response) => {
      
    })
    
  }, [])


  return (
    <>

      <Modal
        size="md"
        show={lgShow1}
        onHide={() => {
          setLgShow1(false)
          reset()
          setinvalidCurrentPswrd(false)
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <h4 className="m-0">Change Password</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Label>Old Password</Label>
              <Form.Control
                {...register('old_password', {
                  onChange: () => {
                    setinvalidCurrentPswrd(false)
                  },
                  required: 'Old Password is Required',
                  validate: (value) => !value.trim() == "",
                  minLength: { value: 6, message: 'Minimum 6 characters required' }
                })}
                name='old_password'
                type='password'
                // defaultValue={property?.name}

                placeholder="Old Password"></Form.Control>
              {errors?.old_password?.type != 'required' && invalidCurrentPswrd && <p style={{ color: 'red' }}>Invalid old password</p>}
              {errors?.old_password?.type === 'minLength' && <p className="mt-2" style={{ color: 'red' }}>{errors?.old_password.message}</p>}
              {errors?.old_password?.type === 'required' && <p style={{ color: 'red' }}>{errors?.old_password.message}</p>}
              {errors?.old_password?.type === "validate" && (

                <p style={{ color: 'red' }}>old Password is Required</p>
              )}

            </Form.Group>
            <Form.Group>
              <Label>New Password</Label>
              <Form.Control
                {...register('new_password', {
                  required: 'New Password is Required',
                  // validate: (value) =>  watch('confirm_password') == value,
                  minLength: { value: 6, message: 'Minimum 6 characters required' }
                })}
                type='password'
                name='new_password'
                // defaultValue={property?.name}

                placeholder="New Password"></Form.Control>
              {errors?.new_password?.type === 'minLength' && <p style={{ color: 'red' }}>{errors?.new_password.message}</p>}
              {errors?.new_password?.type === 'required' && <p style={{ color: 'red' }}>{errors?.new_password.message}</p>}
              {/* {errors?.new_password?.type === "validate" && (
                      <Alert message="password does not match" type="error" />
                    )} */}

            </Form.Group>
            <Form.Group>
              <Label>Confirm Password</Label>
              <Form.Control
                {...register('confirm_password', {
                  required: 'Confirm Password is Required',
                  validate: (value) => watch('new_password') == value,
                  minLength: { value: 6, message: 'Minimum 6 characters required' }
                })}
                name='confirm_password'
                type='password'
                // defaultValue={property?.name}

                placeholder="Confirm Password"></Form.Control>
              {errors?.confirm_password?.type === 'minLength' && <p style={{ color: 'red' }}>{errors?.confirm_password.message}</p>}
              {errors?.confirm_password?.type === 'required' && <p style={{ color: 'red' }}>{errors?.confirm_password.message}</p>}
              {errors?.confirm_password?.type === "validate" && (
                <p style={{ color: 'red' }}>New password and confirm password doesn't match</p>

              )}

            </Form.Group>
            {/* <Form.Group>
        <Label>New Password</Label>
        <Form.Control placeholder="New Password"></Form.Control>
        </Form.Group>
        <Form.Group>
        <Label>Confirm Password</Label>
        <Form.Control placeholder="Confirm Password"></Form.Control>
        </Form.Group> */}
            <Button className="btn w-100" type="submit" variant="primary">
              UPDATE
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Container fluid >


        <Row>
          <Col>
            <Card className="vh-100 border-0">
              <Card.Body className="p-md-5">


                <Card>
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col md="9">
                        <h4 className="m-0">Change Password</h4>
                        {/* <p className="card-category m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                      </Col>
                      <Col md="3" className="my-2 mx-0">
                        <Button className="btn btn-md  m-0" type="submit" variant="primary" onClick={() => setLgShow1(true)}>Change</Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <PointCalculation />

              </Card.Body>
            </Card>


          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Settings;

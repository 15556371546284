import { useEffect, useState } from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Col,
  // Table,
  Container,
  Form,
  Row,
} from "react-bootstrap";

// core components
import { Skeleton, Table } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { GetRewards } from "Redux/Features/CounterSlice";
import Cookies from "js-cookie";

const _currencyformatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

//format an amount to USD currency type
export const currencyformatter = (amount) =>
  _currencyformatter.format(amount || 0);

function ReactTables() {
  const history = useHistory();
  const { id } = useParams();
  function numberToKFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + "k"
      : Math.sign(num) * Math.abs(num);
  }

  const { Rewards, loading } = useSelector((state) => state.Counter);
  const [sort, setSort] = useState({
    name: false,
    email: false,
    total_cost: false,
    earned_point: false,
    scan_date: false,
  });
  const [params, setParams] = useState({
    populate: `[]`,
    page: 1,
    offset: 0,
    limit: 10,
    sort: `[["created_at", "desc"]]`,
    filter: "",
    search: "",
    where: { active: true, is_claimed: true },
    id: true,
  });

  // ANTD TABLE

  const [basicData, setBasicData] = useState({
    rows: [],
  });

  const page = id == ":id" ? 0 : id - 1;
  const columns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index, prop) => page * 10 + index + 1,
    },
    {
      title: " Name",
      dataIndex: "name",
      render: (text, prop) => (
        <a
          onClick={() =>
            history.push({
              pathname: `/admin/user-view/${prop.user_id}`,
              state: {
                page: 4,
              },
            })
          }
        >
          {text}
        </a>
      ),
      onHeaderCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setParams({
              sort: `[["user","full_name", ${
                sort.name ? ` "desc" ` : ` "asc" `
              }]]`,
              id: !id,
            });
            sessionStorage.setItem(
              "Reward_key",
              `[["user","full_name", ${sort.name ? ` "desc" ` : ` "asc" `}]]`
            );
            setSort({
              name: !sort.name,
            });
          },
        };
      },
      sorter: {},
      width: "10%",
      // responsive: ["sm"]
    },

    {
      title: "E-mail",
      dataIndex: "email",
      //   render: (text, prop) => <a onClick={

      //     () => history.push({
      //     pathname: `/admin/user-view/${prop.user_id}`,
      //     state: {
      //       page: 4
      //     }
      //   }

      //   )
      // }>{text}</a>,
      onHeaderCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setParams({
              sort: `[["user","email", ${
                sort.email ? ` "desc" ` : ` "asc" `
              }]]`,
            });
            sessionStorage.setItem(
              "Reward_key",
              `[["user","email", ${sort.email ? ` "desc" ` : ` "asc" `}]]`
            );

            setSort({
              email: !sort.email,
            });
          },
        };
      },
      sorter: {
        // compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 3,
      },
      // responsive: ["sm"]
    },
    {
      title: "Ticket Cost",
      dataIndex: "total_amount",
      ellipsis: false,
      onHeaderCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setParams({
              sort: `[["total_amount", ${
                sort.total_cost ? ` "asc" ` : ` "desc" `
              }]]`,
            });
            sessionStorage.setItem(
              "Reward_key",
              `[["total_amount", ${sort.total_cost ? ` "asc" ` : ` "desc" `}]]`
            );

            setSort({
              total_cost: !sort.total_cost,
            });
          },
        };
      },
      sorter: {
        multiple: 2,
      },

      responsive: ["sm"],
    },
    {
      title: "Earned Point",
      dataIndex: "point",
      onHeaderCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setParams({
              sort: `[["point", ${
                sort.earned_point ? ` "asc" ` : ` "desc" `
              }]]`,
            });
            sessionStorage.setItem(
              "Reward_key",
              `[["point", ${sort.earned_point ? ` "asc" ` : ` "desc" `}]]`
            );

            setSort({
              earned_point: !sort.earned_point,
            });
          },
        };
      },
      sorter: {
        multiple: 1,
      },
      // width: '10%',
      // responsive: ["sm"]
    },
    {
      title: "Scan Date",
      dataIndex: "scanDate",

      onHeaderCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setParams({
              sort: `[["created_at", ${
                sort.scan_date ? ` "asc" ` : ` "desc" `
              }]]`,
              id: !id,
            });
            sessionStorage.setItem(
              "Reward_key",
              `[["created_at", ${sort.scan_date ? ` "asc" ` : ` "desc" `}]]`
            );

            setSort({
              scan_date: !sort.scan_date,
            });
          },
        };
      },
      sorter: {
        // compare: (a, b) => a.scanDate.localeCompare(b.scanDate),
        multiple: 1,
      },
      // width: '10%',
      // responsive: ["sm"]
    },
    // {
    //   title: 'PROPERTY TYPE',
    //   dataIndex: 'property_type',
    //   sorter: {
    //     compare: (a, b) => a.property_type.localeCompare(b.property_type),
    //     multiple: 1,
    //   },
    //   width: '10%',
    //   responsive: ["sm"]
    // },
    {
      title: "",
      key: "operation ",
      // fixed: 'right',
      width: 100,
      render: (prop) => (
        <h5
          onClick={() => history.push(`/admin/reward-view/${prop.id}`)}
          class="text-center"
        >
          <a>
            <h5>
              <i class="fa fa-eye text-primary"></i>
            </h5>
          </a>
        </h5>
      ),
    },
  ];

  // ANTD TABLE END
  var item_value = sessionStorage.getItem("Reward_key");

  const dispatch = useDispatch();
  useEffect(() => {
    !Cookies.get("hedge_token") && history.push("/auth/Login");
    sessionStorage.setItem("Reward_key", `[["created_at", "desc" ]]`);
  }, []);
  useEffect(() => {
    dispatch(
      GetRewards({ offset: id == ":id" ? 0 : id - 1, params: item_value })
    ).then((res) => {
      setBasicData({
        rows: res?.payload?.rewards?.map((i) => ({
          key: i.id,
          id: i.id,
          user_id: i.user.id,
          name: i.user.full_name,
          email: i.user.email,
          total_amount:
            i.total_amount == null
              ? "$ 0"
              : `${currencyformatter(i.total_amount).split(".")[0]}`,
          point: i.point,
          scanDate: moment(i.created_at).format("DD MMMM YYYY, h:mm a"),
        })),
      });
    });
  }, [params?.sort]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title mt-0">Scanned Receipts</h4>
            <Card className="border-0">
              <Card.Body className="p-0">
                {/* block start */}

                <Row className="mt-4 px-3">
                  <Col lg="4" sm="6">
                    <Card className="card-stats w-75">
                      <Card.Body>
                        <Row className="align-items-center">
                          <Col xs="5">
                            <div className="icon-big text-left icon-warning">
                              <i className="nc-icon nc-paper-2 text-primary"></i>
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <Card.Title as="h4">Total Receipts </Card.Title>
                              {/* <Card.Title as="h4">400</Card.Title>  */}
                              {loading ? (
                                <Card.Title as="h4">
                                  <Skeleton.Input
                                    width={2}
                                    title={true}
                                    active
                                    size={"small"}
                                  />
                                </Card.Title>
                              ) : (
                                <Card.Title as="h4">
                                  ({Rewards?.count})
                                </Card.Title>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col lg="4" sm="6">
                    <Card className="card-stats w-75">
                      <Card.Body>
                        <Row className="align-items-center">
                          <Col xs="5">
                            <div className="icon-big text-left icon-warning">
                              <i className="nc-icon nc-money-coins text-danger"></i>
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <Card.Title as="h4">Total Ticket Cost</Card.Title>
                              {/* <Card.Title as="h4">400</Card.Title>  */}
                              {loading ? (
                                <Skeleton.Input active size={"small"} />
                              ) : (
                                <Card.Title as="h4">
                                  {" "}
                                  (
                                  {
                                    currencyformatter(
                                      Rewards?.total_amount
                                    ).split(".")[0]
                                  }
                                  )
                                </Card.Title>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col lg="4" sm="6">
                    <Card className="card-stats w-75">
                      <Card.Body>
                        <Row className="align-items-center">
                          <Col xs="5" lg="2">
                            <div className="icon-big text-left icon-warning">
                              <i className="nc-icon nc-bag text-danger"></i>
                            </div>
                          </Col>
                          <Col xs="7" lg="10">
                            <div className="numbers">
                              <Card.Title as="h4">
                                Total Earned Points
                              </Card.Title>
                              {loading ? (
                                <Skeleton.Input active size={"small"} />
                              ) : (
                                <Card.Title as="h4">
                                  {" "}
                                  ({Rewards?.total_point})
                                </Card.Title>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                {/* Block end */}
                <Table
                  // onChange={onChange}
                  pagination={{
                    // pageSize: 10,
                    showSizeChanger: false,
                    defaultCurrent: id == ":id" ? 1 : parseInt(id),
                    total: Rewards.count,

                    onChange: (page) => {
                      history.push(`/admin/Rewards/${page}`);
                      dispatch(
                        GetRewards({ offset: page - 1, params: item_value })
                      ).then((res) => {
                        // (res.payload.rewards, 'res');
                        setBasicData({
                          rows: res?.payload?.rewards?.map((i) => ({
                            key: i.id,
                            id: i.id,
                            user_id: i.user.id,
                            name: i.user.full_name,
                            email: i.user.email,
                            total_amount:
                              i.total_amount == null
                                ? "$ 0"
                                : `$ ${i.total_amount}`,
                            point: i.point,
                            scanDate: moment(i.created_at).format(
                              "DD MMMM YYYY, h:mm a"
                            ),
                          })),
                        });
                      });
                    },
                  }}
                  def
                  size="small"
                  loading={loading}
                  columns={columns}
                  dataSource={basicData.rows}
                  // showSorterTooltip={false}
                  // showSizeChanger={false}
                  // onChange={onChange}
                />
                {/* <Pagination defaultCurrent={2} total={50} />; */}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* <RewardsView/> */}
      </Container>
    </>
  );
}

export default ReactTables;

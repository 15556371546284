
import { useEffect, useState } from 'react';
import { Badge, Button, Form, Input, message, Modal, Skeleton, Space, Spin, Tooltip, Upload } from 'antd';
import { UploadOutlined, EditOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateSportsBook } from 'Redux/Features/DetailSlice';
import { uploadImagesToS3 } from 'AWS/awsS3';
import { FiEdit } from "react-icons/fi";
const SportsbookeditModal = ({ setupdateState, openedit, setopenedit }) => {
    const { TextArea } = Input
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const dispatch = useDispatch()
    const [imageurl, setimageurl] = useState();
    const { sportsBookDetail, loading } = useSelector(state => state.Details)

    useEffect(() => {
        setimageurl(sportsBookDetail.image)
    }, [sportsBookDetail.image])

    console.log(imageurl, 'imaurl');
    return (
        <>

            <Modal
                centered
                open={openedit}
                title={<div style={{ textAlign: 'center' }}>Edit Sportsbook</div>}
                okText="Update"
                cancelText="Cancel"
                onCancel={() => setopenedit(false)}
                width={'35%'}
                onOk={() => {
                    form
                        .validateFields()
                        .then(async (values) => {
                            // form.resetFields();
                            // console.log(values, 'values');
                            if (imageurl === sportsBookDetail.image) {
                                setConfirmLoading(true)
                                dispatch(UpdateSportsBook({ id: sportsBookDetail?.id, image: sportsBookDetail.image, values })).then((response) => {
                                    // console.log(response, 'response after edit');
                                    setConfirmLoading(false)
                                    if (response.type === "data/updatesportsbook/fulfilled") {
                                        setupdateState(true)
                                        setupdateState(false)
                                        setopenedit(false)
                                        setConfirmLoading(false)
                                        message.success('SportsBook Updated Successfully')
                                    }
                                })
                            } else {
                                // console.log('change in image');
                                setConfirmLoading(true)
                                await uploadImagesToS3(imageurl?.originFileObj).then((res) => {
                                    // console.log(res.location, 'response after uploading images');
                                    if (res.location) {
                                        dispatch(UpdateSportsBook({ id: sportsBookDetail?.id, image: res.location, values })).then((response) => {
                                            // console.log(response, 'after adding book');
                                            if (response.type === "data/updatesportsbook/fulfilled") {
                                                setupdateState(true)
                                                setupdateState(false)
                                                setopenedit(false)
                                                setConfirmLoading(false)
                                                message.success('SportsBook Updated Successfully')
                                            }
                                            setConfirmLoading(false)
                                        })
                                    }
                                })
                            }


                        })
                        .catch((info) => {
                            // console.log('Validate Failed:', info);
                        });
                }}
            >
                {!sportsBookDetail.state ?
                    <Form
                        form={form}
                        layout="vertical"
                        disabled
                    // name="form_in_modal"

                    >
                        <Form.Item
                            name="State"
                            className='mt-2'
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'State Is required !',
                                },


                                {
                                    min: 3,
                                    message: 'Atleast 3 characters required'
                                }
                            ]}
                        >
                            <Input size='large' placeholder='State' />
                        </Form.Item>
                        <Form.Item
                            hasFeedback

                            className='my-4'
                            name="Casino"

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Casino is Required !'
                                },
                                {
                                    min: 3,
                                    message: 'Atleast 3 characters required'
                                }
                            ]}
                        >
                            <Input size='large' placeholder='Casino' />
                        </Form.Item>
                        <Form.Item
                            className='mt-4'
                            name="address"

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Address is Required !'
                                },
                                {
                                    min: 5,
                                    message: 'Atleast 5 characters required'
                                }
                            ]}
                        >
                            {/* <TextArea rows={4} placeholder='Address' /> */}
                            <Input size='large' autoSize={{ minRows: 3, maxRows: 6 }} type="textarea" placeholder='Address' />
                        </Form.Item>

                        <Form.Item
                            className='mt-4'
                            name="latitude"

                            rules={[
                                {
                                    required: true,
                                    message: 'Latitude is Required !'
                                }
                            ]}
                        >
                            <Input size='large' type="number" placeholder='Latitude' />
                        </Form.Item>
                        <Form.Item
                            className='mt-4'
                            name="logtitude"

                            rules={[
                                {
                                    required: true,
                                    message: 'Longitude is Required !'
                                }
                            ]}
                        >
                            <Input size='large' type="number" placeholder='Longitude' />
                        </Form.Item>
                        <Form.Item
                            className='mt-4'
                            name="sportsbook"

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Sportsbook is Required !'
                                },
                                {
                                    min: 3,
                                    message: 'Atleast 3 characters required'
                                }
                            ]}
                        >
                            <Input size='large' placeholder='SportsBook' />
                        </Form.Item>
                        <Form.Item
                            name="image"
                            initialValue={sportsBookDetail.image}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Image is required'
                                },

                            ]}
                        >
                            <Space direction="vertical" style={{ width: '100%' }} size="large">
                                <Upload
                                    action={null}
                                    listType="picture"
                                    maxCount={1}
                                    customRequest={
                                        async (options) => {
                                            const { onSuccess, onError, file } = options;
                                            await onSuccess("Ok");
                                        }
                                    }
                                    beforeUpload={(file) => {
                                        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                        if (!isJpgOrPng) {
                                            message.error('You can only upload JPG/PNG file!');
                                        }
                                        const isLt2M = file.size / 1024 / 1024 < 2;
                                        if (!isLt2M) {
                                            message.error('Image must smaller than 2MB!');
                                        }
                                        return isJpgOrPng && isLt2M;
                                    }}
                                    onChange={(info) => {
                                        console.log(info?.file, 'file details');
                                        setimageurl(info?.file)

                                        info.file.status === 'removed' && setimageurl(sportsBookDetail.image)
                                    }}
                                >
                                    <Skeleton.Image active />
                                </Upload>
                            </Space>
                        </Form.Item>

                    </Form>
                    :
                    <Spin tip="SportsBook Being Updated" spinning={confirmLoading}>
                        <Form
                            form={form}
                            layout="vertical"

                            // name="form_in_modal"
                            initialValues={{
                                State: sportsBookDetail?.state,
                                Casino: sportsBookDetail?.casino,
                                address: sportsBookDetail?.address,
                                latitude: sportsBookDetail?.latitude,
                                logtitude: sportsBookDetail?.longitude,
                                sportsbook: sportsBookDetail?.sportsbook,
                                // logtitude: sportsBookDetail?.state,
                            }}
                        >
                            <Form.Item
                                name="State"
                                className='mt-2'
                                rules={[
                                    {
                                        required: true,
                                        message: 'State is required!',
                                    },
                                    {
                                        whitespace: true,
                                        message: 'State is required!',
                                    },
                                    {
                                        min: 3,
                                        message: 'Atleast 3 characters required',
                                    },
                                    {
                                        required: false,
                                        validator: (rule, value = "") => {
                                            if (value.trim().length != 0) {
                                                if (
                                                    /^(?![0-9]+$).+$/i.test(
                                                        value
                                                    )
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject("State cannot be only numbers");
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Input size='large' placeholder='State' />
                            </Form.Item>
                            <Form.Item


                                className='my-4'
                                name="Casino"

                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: 'Casino is Required !'
                                    },
                                    {
                                        min: 3,
                                        message: 'Atleast 3 characters required'
                                    }
                                ]}
                            >
                                <Input size='large' placeholder='Casino' />
                            </Form.Item>
                            <Form.Item
                                className='mt-4'
                                name="address"

                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: 'Address is Required !'
                                    },
                                    {
                                        min: 5,
                                        message: 'Atleast 5 characters required'
                                    }
                                ]}
                            >
                                {/* <TextArea rows={4} placeholder='Address' /> */}
                                <Input size='large' minLength={5} autoSize={{ minRows: 3, maxRows: 6 }} type="textarea" placeholder='Address' />
                            </Form.Item>

                            <Form.Item
                                className='mt-4'
                                name="latitude"

                                rules={[
                                    {
                                        required: true,
                                        message: 'Latitude is Required !'
                                    }
                                ]}
                            >
                                <Input size='large' type="number" placeholder='Latitude' />
                            </Form.Item>
                            <Form.Item
                                className='mt-4'
                                name="logtitude"

                                rules={[
                                    {
                                        required: true,
                                        message: 'Longitude is Required !'
                                    }
                                ]}
                            >
                                <Input size='large' type="number" placeholder='Longitude' />
                            </Form.Item>
                            <Form.Item
                                className='mt-4'
                                name="sportsbook"

                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: 'Sportsbook is Required !'
                                    },
                                    {
                                        min: 3,
                                        message: 'Atleast 3 characters required'
                                    }
                                ]}
                            >
                                <Input size='large' placeholder='SportsBook' />
                            </Form.Item>
                            <Form.Item
                                name="image"
                                initialValue={sportsBookDetail.image}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: 'Image is required'
                                    },

                                ]}
                            >
                                <Space direction="vertical" style={{ width: '100%' }} size="large">
                                    <Upload
                                        action={null}
                                        listType="picture"
                                        maxCount={1}
                                        customRequest={
                                            async (options) => {
                                                const { onSuccess, onError, file } = options;
                                                await onSuccess("Ok");
                                            }
                                        }
                                        beforeUpload={(file) => {
                                            const isFileSizeValid = file.size <= 1 * 1024 * 1024;
                                            if (isFileSizeValid) {
                                                const isPNG = file.type === 'image/jpeg' || file.type === 'image/png';

                                                if (!isPNG) {
                                                    message.error(`${file.name} is not a png/jpeg file`);
                                                    form.setFieldValue('image', '')
                                                    return Upload.LIST_IGNORE
                                                } else {
                                                    return isPNG
                                                }

                                            } else {
                                                message.error('Please upload image file within 2MB!');
                                                return Upload.LIST_IGNORE
                                            }
                                        }}
                                        onChange={(info) => {
                                            console.log(info?.file, 'file details');
                                            setimageurl(info?.file)

                                            info.file.status === 'removed' && setimageurl(sportsBookDetail.image)
                                        }}
                                        
                                            
                                        
                                    >

                                        {imageurl?.thumbUrl === undefined &&
                                       
                                       
                                            <div style={{ position: 'relative' }}>
                                                 { console.log(imageurl,'imageurl in div')}


                                                <img
                                                    src={imageurl}
                                                    height='150px'
                                                    alt=" "
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                />
                                                <FiEdit style={{ position: 'absolute', top: 8, right: 8, fontSize: '24px', color: 'white',backdropFilter:'contrast(0.3)' }} />

                                            </div>
                                        }



                                    </Upload>
                                </Space>
                            </Form.Item>

                        </Form>
                    </Spin>

                }


            </Modal>
        </>
    );
};
export default SportsbookeditModal;